import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { Sentry } from "sentry";

import { useAppContext } from "#components/AppContext";
import ImportantMessage from "#components/ImportantMessage";
import { NAV_ID } from "#components/Nav";

import styles from "./Announcements.module.css";

let globalHideImportantMessage = false;

const AnnouncementsComponent = ({ targetSelector }) => {
  const { api, language } = useAppContext();
  const [data, setData] = React.useState([]);

  const [hideImportantMessage, setHideImportantMessage] = React.useState(
    globalHideImportantMessage
  );

  const { current: containerElement } = React.useRef(
    document.createElement("div")
  );
  containerElement.classList.add(styles.root);

  const targetElement = document.querySelector(targetSelector);
  const messages = data.filter(Boolean);

  const widgets = (
    <>
      {!hideImportantMessage && messages.length > 0 ? (
        <ImportantMessage
          onClose={() => {
            setHideImportantMessage(true);
            globalHideImportantMessage = true;
          }}
          messages={messages}
          detached={true}
        />
      ) : undefined}
    </>
  );

  React.useEffect(() => {
    targetElement?.appendChild(containerElement);

    return () => {
      targetElement?.removeChild(containerElement);
    };
  }, [containerElement, targetElement]);

  React.useEffect(() => {
    (async () => {
      try {
        const announcementsResponse = await api.getAnnouncements();
        setData(announcementsResponse?.data);
      } catch (error) {
        console.error(error, error.response);
        Sentry.captureException(error);
      }
    })();
  }, [api, language]);

  if (!messages.length || !targetElement) {
    return null;
  }

  return ReactDOM.createPortal(widgets, containerElement);
};

AnnouncementsComponent.propTypes = {
  targetSelector: PropTypes.string,
};

AnnouncementsComponent.defaultProps = {
  targetSelector: `#${NAV_ID}`,
};

export default function Announcements({ ...restProps }) {
  const [isMounted, setIsMounted] = React.useState(false);
  const isClient = typeof document !== "undefined";

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  return isClient && isMounted ? (
    <AnnouncementsComponent {...restProps} />
  ) : null;
}
