import classnames from "classnames";
import { Node } from "djedi-react";
import PropTypes from "prop-types";
import React from "react";

import Message from "#components/Message";
import Text from "#components/Text";
import { MESSAGE_BANNER_ID } from "#containers/Booking/consts";
import { AnnouncementMessageType } from "#utils/enums";

import styles from "./ImportantMessage.module.css";

ImportantMessage.propTypes = {
  messages: PropTypes.array,
  variant: PropTypes.oneOf([
    "general",
    "departure",
    "alteration",
    "planning",
    "detached",
    "corporate",
  ]),
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
  detached: PropTypes.bool,
};

ImportantMessage.defaultProps = {
  messages: [],
  variant: "general",
  onClose: undefined,
  className: "",
  detached: false,
};

export default function ImportantMessage({
  children,
  onClose,
  messages,
  variant,
  detached,
  className,
}) {
  const showHeading = [
    "planning",
    "general",
    "trips",
    "detached",
    "corporate",
  ].includes(variant);

  const messageRef = React.useRef(null);

  const setBannerHeightVariable = function (height) {
    document.body.style.setProperty(
      "--message-banner-height",
      height !== 0 ? `calc(${height ?? 0}px + 16px)` : "0"
    );
  };

  React.useEffect(() => {
    if (!messageRef.current) return;

    if (!detached) return;

    const observer = new ResizeObserver(() => {
      setBannerHeightVariable(messageRef.current.clientHeight);
    });

    for (const child of messageRef.current.children) {
      observer.observe(child);
    }

    return () => observer.disconnect();
  }, [detached, messageRef]);

  const childrenList = (
    children ? (children.constructor === Array ? children : [children]) : []
  ).filter((child) => Boolean(child));

  const messagesInformation = messages
    .filter(
      (message) =>
        message.type === AnnouncementMessageType.INFORMATION ||
        message.type === undefined
    )
    .map((message) =>
      message.content.type === Node ? (
        message.content
      ) : (
        <Text
          key={message.content}
          coloredLinks={false}
          html={message.content}
        />
      )
    );

  const messagesImportant = messages
    .filter((message) => message.type === AnnouncementMessageType.IMPORTANT)
    .map((message) =>
      message.content.type === Node ? (
        message.content
      ) : (
        <Text
          key={message.content}
          coloredLinks={false}
          html={message.content}
        />
      )
    )
    .concat(childrenList);
  const messagesCritical = messages
    .filter((message) => message.type === AnnouncementMessageType.CRITICAL)
    .map((message) =>
      message.content.type === Node ? (
        message.content
      ) : (
        <Text
          key={message.content}
          coloredLinks={false}
          html={message.content}
        />
      )
    );

  return (
    <div
      id={MESSAGE_BANNER_ID}
      ref={messageRef}
      className={classnames(
        {
          [styles.overlay]: variant === "general",
        },
        styles.container,
        className
      )}
    >
      {messagesInformation.length > 0 && (
        <Message
          className={styles.information}
          heading={
            showHeading && (
              <Node uri="ImportantMessage/information">Information</Node>
            )
          }
          onClose={onClose}
          detached={detached}
          variant={variant}
        >
          {messagesInformation}
        </Message>
      )}
      {messagesImportant.length > 0 && (
        <Message
          className={styles.important}
          heading={
            showHeading && (
              <Node uri="ImportantMessage/important">Viktig information</Node>
            )
          }
          onClose={onClose}
          detached={detached}
          variant={variant}
        >
          {messagesImportant}
        </Message>
      )}
      {messagesCritical.length > 0 && (
        <Message
          className={styles.critical}
          heading={
            showHeading && (
              <Node uri="ImportantMessage/critical">Viktig information</Node>
            )
          }
          onClose={onClose}
          detached={detached}
          variant={variant}
        >
          {messagesCritical}
        </Message>
      )}
    </div>
  );
}
