import classnames from "classnames";
import { Node } from "djedi-react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React from "react";

import InfoIcon from "#icons/new/streamline-icon-alert-circle@20x20.svg";
import CloseIcon from "#icons/new/streamline-icon-remove@24x24.svg";

import styles from "./Message.module.css";

export const MAX_WIDTH_REGULAR = 1136;

Message.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  detached: PropTypes.bool,
  variant: PropTypes.oneOf([
    "general",
    "departure",
    "alteration",
    "planning",
    "detached",
    "corporate",
  ]),
};

Message.defaultProps = {
  children: PropTypes.node.isRequired,
  heading: undefined,
  className: "",
  onClose: undefined,
  detached: false,
  variant: "general",
};

export default function Message({
  children,
  onClose,
  heading,
  className,
  detached,
  variant,
}) {
  const router = useRouter();
  const { pathname } = router;

  const widthReduced =
    detached &&
    ["", "min-resa", "tidtabell-trafikinformation", "konto"].includes(
      pathname.split("/")[1]
    );

  const setBannerHeightVariable = function (height) {
    document.body.style.setProperty(
      "--message-banner-height",
      height !== 0 ? `calc(${height ?? 0}px + 16px)` : "0"
    );
  };

  return (
    <div
      style={{ maxWidth: widthReduced ? MAX_WIDTH_REGULAR : undefined }}
      className={classnames(
        styles.container,
        {
          [styles.detached]: detached,
        },
        className
      )}
    >
      <div
        className={classnames(styles.root, {
          [styles.general]: variant === "general",
          [styles.trips]: variant === "trips",
          [styles.departure]: variant === "departure",
          [styles.alteration]: variant === "alteration",
          [styles.planning]: variant === "planning",
          [styles.detached]: variant === "detached",
          [styles.corporate]: variant === "corporate",
        })}
      >
        <div className={styles.message}>
          <div className={styles.header}>
            {heading && (
              <div className={styles.heading}>
                <InfoIcon className={styles.infoIcon} />
                <strong>
                  {variant === "planning" ? (
                    <Node uri="ImportantMessage/planning-heading">
                      Resor under planering
                    </Node>
                  ) : variant === "trips" ? (
                    <Node uri="ImportantMessage/trips-heading">
                      Information om din resa
                    </Node>
                  ) : variant === "corporate" ? (
                    <Node uri="ImportantMessage/corporate-heading">
                      Information för företagsresenären
                    </Node>
                  ) : (
                    heading
                  )}
                </strong>
              </div>
            )}

            {onClose && (
              <button
                type="button"
                onClick={() => {
                  setBannerHeightVariable(0);
                  return onClose();
                }}
                className={styles.closeButton}
                aria-label="Close"
              >
                <CloseIcon className={styles.closeIcon} />
              </button>
            )}
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}
